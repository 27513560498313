import { defineMessages } from 'react-intl';

const socialNetworks = defineMessages({
  title: {
    id: 'pages.layout.footer.socialNetworks.title',
    defaultMessage: '{product, select, livejasmin {Follow LiveJasmin} other {Follow Oranum}}',
  },
  twitter: {
    id: 'pages.layout.footer.socialNetworks.twitter',
    defaultMessage: 'Twitter',
  },
  instagram: {
    id: 'pages.layout.footer.socialNetworks.instagram',
    defaultMessage: 'Instagram',
  },
  facebook: {
    id: 'pages.layout.footer.socialNetworks.facebook',
    defaultMessage: 'Facebook',
  },
  tikTok: {
    id: 'pages.layout.footer.socialNetworks.tikTok',
    defaultMessage: 'TikTok',
  },
});

const workWithUs = defineMessages({
  title: {
    id: 'pages.layout.footer.workWithUs.title',
    defaultMessage: 'Work with us',
  },
  becomeAModel: {
    id: 'pages.layout.footer.workWithUs.becomeAModel',
    defaultMessage: 'Become a Model',
  },
  studioSignUp: {
    id: 'pages.layout.footer.workWithUs.studioSignUp',
    defaultMessage: 'Studio Sign Up',
  },
  adultAffiliateProgram: {
    id: 'pages.layout.footer.workWithUs.adultAffiliateProgram',
    defaultMessage: 'Adult Affiliate Program',
  },
  certifiedPartners: {
    id: 'pages.layout.footer.workWithUs.certifiedPartners',
    defaultMessage: 'Certified Partners',
  },
  expertsLogin: {
    id: 'pages.layout.footer.workWithUs.expertsLogin',
    defaultMessage: 'Experts Login',
  },
  expertsWanted: {
    id: 'pages.layout.footer.workWithUs.expertsWanted',
    defaultMessage: 'Experts Wanted',
  },
  affiliateProgram: {
    id: 'pages.layout.footer.workWithUs.affiliateProgram',
    defaultMessage: 'Affiliate Program',
  },
});

const legalAndSafety = defineMessages({
  title: {
    id: 'pages.layout.footer.legalAndSafety.title',
    defaultMessage: 'Legal & Safety',
  },
  servicesAgreement: {
    id: 'pages.layout.footer.legalAndSafety.servicesAgreement',
    defaultMessage: 'Services Agreement',
  },
  ownershipStatement: {
    id: 'pages.layout.footer.legalAndSafety.ownershipStatement',
    defaultMessage: 'Ownership Statement',
  },
  privacyPolicy: {
    id: 'pages.layout.footer.legalAndSafety.privacyPolicy',
    defaultMessage: 'Privacy Policy',
  },
  cookiePolicy: {
    id: 'pages.layout.footer.legalAndSafety.cookiePolicy',
    defaultMessage: 'Cookie Policy',
  },
});

const helpAndSupport = defineMessages({
  title: {
    id: 'pages.layout.footer.helpAndSupport.title',
    defaultMessage: 'Help & Support',
  },
  onlineSupport: {
    id: 'pages.layout.footer.helpAndSupport.onlineSupport',
    defaultMessage: 'Online Support',
  },
  faq: {
    id: 'pages.layout.footer.helpAndSupport.faq',
    defaultMessage: 'F.A.Q.',
  },
});

export default {
  pages: {
    layout: {
      footer: {
        ...defineMessages({
          copyright: {
            id: 'pages.layout.footer.copyright',
            defaultMessage: '© {year} {product} - All Rights Reserved!',
          },
          operation: {
            id: 'pages.layout.footer.operation',
            defaultMessage:
              'This site is operated by <a1>JWS Americas S.à r.l.</a1> and <a2>JWS International S.à r.l.</a2>.',
          },
          compliance: {
            id: 'pages.layout.footer.compliance',
            defaultMessage: '<a>18 U.S.C. 2257 Record-Keeping Requirements Compliance Statement.</a>',
          },
          disclaimer: {
            id: 'pages.layout.footer.disclaimer',
            defaultMessage:
              'The site contains sexually explicit material. Enter this site ONLY if you are over the age of 18 and agree to our <a>Cookie Policy</a>.',
          },
        }),
        socialNetworks,
        workWithUs,
        legalAndSafety,
        helpAndSupport,
      },
    },
  },
};

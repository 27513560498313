import React from 'react';
import { map } from 'rxjs';
import settings from 'configurations/application';
import useNavigation from 'enhancers/use-navigation';
import authentication from 'services/authentication';
import application from 'services/application';
import useReadonlyObservable from 'enhancers/use-readonly-observable';
import user from 'store/user';
import account from 'store/account/account';

import UI from './header.ui';

const Header: React.FunctionComponent<unknown> = () => {
  const [, , link] = useNavigation();
  const [content] = useReadonlyObservable(application.header.onContentChange$);
  const [isNavigationMenuOpen] = useReadonlyObservable(application.container.onNavigationMenuVisibilityChange$, false);
  const [isUserAuthenticated] = useReadonlyObservable(authentication.event.onChange$, authentication.isAuthenticated());
  const [removeMenu] = useReadonlyObservable<boolean>(
    application.container.onRemoveMenuChange$,
    application.container.data.removeMenu
  );
  const [userViewType] = useReadonlyObservable(
    user.onViewTypeChange$.pipe(map(({ viewType }) => viewType)),
    user.data?.viewType
  );

  const handleOnHamburgerToggle = React.useCallback(() => {
    application.container.toggleMobileNavigationMenu(!isNavigationMenuOpen);
  }, [isNavigationMenuOpen]);

  const showHamburger = !removeMenu && isUserAuthenticated && account.hasEnrollmentCompleted;

  const logoLink = (): string => {
    if (!isUserAuthenticated) {
      return link.landing({ forceShow: '1' });
    }

    if (account.hasEnrollmentCompleted) {
      if (userViewType === 'studio') {
        return link.models();
      }

      return link.dashboard();
    }

    // @ts-expect-error Extending the ApplicationRoute provides too many variants to interpret
    return link[settings.homepage]();
  };

  return (
    <UI
      viewType={userViewType}
      showHamburger={showHamburger}
      isNavigationMenuOpen={isNavigationMenuOpen}
      onHamburgerToggle={handleOnHamburgerToggle}
      logoLink={logoLink()}
    >
      {content}
    </UI>
  );
};

export { Header };
export default React.memo(Header);
